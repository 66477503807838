import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../input/Input";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import { Button } from "../button/Button";
import { materialAPI } from "../../api/apiRequests";
import { Checkbox } from "../checkbox/Checkbox";
import { AxiosResponse } from "axios";
import { selectCustomStyles } from "../../constants/selectStyles";
import CreatableSelect from "react-select/creatable";
import useUser from "../../api/SWR/useUser";
import { productComponentNotification } from "../../api/SWR/fetchers";
const validationSchema = Yup.object().shape({
  internalReference: Yup.string().required("Required"),
  componentName: Yup.string().required("Required"),
  manufacturer: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  // requiredCertificates: Yup.array().required("Required"),
  critical: Yup.boolean().required("Required"),
});

const AddMaterial = ({
  productID,
  mutate,
  productname,
  closeModal,
}: {
  productID: number | null;
  mutate: any;
  productname: string;
  closeModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedCertsOptions, setSuggestedCertsOptions] = useState();
  const [requiredCertValues, setRequiredCertValues] = useState([]);
  const { data: userData } = useUser();
  const formik = useFormik({
    validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      internalReference: "",
      componentName: "",
      manufacturer: "",
      type: "",
      critical: false,
    },
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      if (productID) {
        try {
          const res = await materialAPI.saveMaterial({
            internalReference: values.internalReference,
            componentName: values.componentName,
            manufacturer: values.manufacturer,
            type: values.type,
            requiredCertificates: requiredCertValues.map(
              (item: { value: string; label: string }) => item.label
            ),
            status: 1,
            critical: values.critical,
            productId: productID,
          });
          await mutate();
          toast.success("Material created!");
          const productUrl = window.location.href;
          const emailData = {
            target: "component",
            itemName: values.componentName,
            itemUrl: productUrl,
            userEmail: userData?.email,
            userFirstName: userData?.firstName,
            userLastName: userData?.lastName,
            userCompany: userData?.company?.name,
            productName: productname,
          };
          console.log("email payload", emailData);

          await productComponentNotification(emailData);
          closeModal();
        } catch (e) {
          setIsLoading(false);
          toast.error("Something went wrong");
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  useEffect(() => {
    getRequiredCertificates();
  }, []);
  const getRequiredCertificates = async () => {
    await materialAPI.getRequiredCertificates().then((requiredCerts: any) => {
      setSuggestedCertsOptions(
        requiredCerts?.map((certificate: any) => ({
          label: certificate.title,
          value: certificate.title,
        }))
      );
    });
  };
  const handleChange = (e: any) => {
    setRequiredCertValues(e);
  };
  return (
    <div className={styles.wrapper}>
      <form onSubmit={formik.handleSubmit} className={"flex flex-col mb-6"}>
        <div className={"flex justify-between  mb-5"}>
          <Input
            type="text"
            label="Internal Reference"
            id="internalReference"
            name="internalReference"
            onChange={formik?.handleChange}
            value={formik?.values?.internalReference}
            errorText={formik?.errors?.internalReference}
            placeholder="Internal Reference"
          />

          <Input
            type="text"
            label="Component Name"
            id="componentName"
            name="componentName"
            onChange={formik?.handleChange}
            value={formik?.values?.componentName}
            errorText={formik?.errors?.componentName}
            placeholder="Component Name"
          />
        </div>

        <div className={"flex  justify-between mb-5"}>
          <Input
            type="text"
            label="Manufacturer Name"
            id="manufacturer"
            name="manufacturer"
            onChange={formik?.handleChange}
            value={formik?.values?.manufacturer}
            errorText={formik?.errors?.manufacturer}
            placeholder="Manufacturer Name"
          />

          <Input
            type="text"
            label="Component Type"
            id="type"
            name="type"
            onChange={formik?.handleChange}
            value={formik?.values?.type}
            errorText={formik?.errors?.type}
            placeholder="Component Type"
          />
        </div>
        <div className={"flex justify-between"}>
          {/* <Input
            type="text"
            label="Required Certificates"
            id="requiredCertificates"
            name="requiredCertificates"
            onChange={formik?.handleChange}
            value={formik?.values?.requiredCertificates}
            errorText={formik?.errors?.requiredCertificates}
            placeholder="Required Certificates"
          /> */}
          {/* <div>
            <label className="Input__label">{"Required certificates"}</label>
            <CreatableSelect
              isMulti={true}
              styles={selectCustomStyles}
              options={suggestedCertsOptions}
              value={requiredCertValues || []}
              onChange={handleChange}
            />
          </div> */}
        </div>
        <div className={"mt-8"}>
          <Checkbox
            name={""}
            options={[{ value: "", label: "Critical" }]}
            onChange={(e) => {
              formik.setFieldValue("critical", e.checked);
            }}
          />
        </div>
      </form>
      <div className="flex">
        <Button
          type={"submit"}
          label="Add component"
          className="ml-auto"
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
        />
      </div>
    </div>
  );
};

export default AddMaterial;
