import axios from "axios";
import useUser from "../../api/SWR/useUser";
import { baseUrl } from "../../api/constants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "../button/Button";
import { DOCUMENTCERTIFICATES } from "../../constants/enums";
import EditRoHSCertificateField from "../ui/edit-certificate-field/EditRoHSCertificateField";
import { Input } from "../input/Input";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { SchaffnerDetail } from "./mockCertificates";
import { Certificates, CertResult, Manufacturer } from "./interfaces";
import styles from "./styles.module.scss";

import { manufactuererData } from "./mockManufacturer";
import CustomModal from "../custom-modal/CustomModal";
import ManufacturerCertificates from "./ManufacturerCertificates";
import {
  addManufactureAPI,
  getAllCertificates,
  getCertificatesByManufactureCdoc,
  getManufacturerByID,
  getManufactures,
} from "../../api/SWR/fetchers";
import ManufacturerFilterDialog from "./ManufacturerFilterDialog";
import { getAccessToken } from "../../api/utils";

const ManfacturerDetails = () => {
  let [cert, setCert] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingManufacture, setLoadingManufacture] = useState<boolean>(false);

  const [searchDD, setSearchDDValue] = useState<any>(
    DOCUMENTCERTIFICATES.source
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [callbackState, setCallbackState] = useState<object>({});
  let [tempCertData, settempCerts] = useState([]);
  const [isAddingScraper, setIsAddingScraper] = useState(false);
  const [newmanufacturerName, setNewManufacturerName] = useState("");

  const [certificates, setCertificates] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [selectedManufacture, setSelectedManufacture] = useState<any>(null);
  const [isManufacturers, setIsManufacturer] = useState<any>([]);
  const [isAddManufacture, setIsAddManufacture] = useState<boolean>(false);
  const [pageCountValue, setPageCountValue] = useState<any>(0);
  let [showingData, setShowingData] = useState([]);
  const itemsPerPage = 50;
  const tableHeadings = [
    "Cdoc Id",
    "Document No",
    "Reference",
    "Model No",
    "Raw Version",
    // "Regex",
    "Company Name",
    "Validity",
    "Reason",
    "Certifiying Body",
    "Link",
  ];

  // State for manufacturers list

  // State for selected manufacturer

  // Fetch manufacturers from API
  useEffect(() => {
    fetchManufacturers();
  }, []);
  const fetchManufacturers = async () => {
    try {
      let data = await getManufactures();
      setManufacturers(data); // Assuming data is an array of manufacturers
    } catch (error) {
      console.error("Error fetching manufacturers:", error);
    }
  };

  // Handle selection change
  const handleManufacturerChange = (e: { target: { value: any } }) => {
    setCertificates([]);
    setPageNumber(1);
    console.log("Selected Manufacturer:", e.target.value); // Optional: Log selected value
    setSelectedManufacture(e.target.value);
  };

  const addManufacture = async (name: string) => {
    try {
      setIsAddingScraper(true);
      let res = await addManufactureAPI(name);
      setIsAddingScraper(false);
      if (res.result) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCertificates = async () => {
    try {
      if (!selectedManufacture) {
        toast.error("Select Manufacturer");
        return;
      }

      setLoading(true);

      if (selectedManufacture === "All") {
        const size = 50; // Fixed batch size
        const data = await getAllCertificates(pageNumber, size);

        if (data.status === 200 || data.status === 201) {
          setCertificates((prevCertificates) => [
            ...prevCertificates,
            ...data.data, // Append new batch
          ]);
          setPageCountValue(data.totalPages);
          setTotalResults(data.total);
          if (data.data.length === 0) {
            toast.info("No more certificates to load.");
          }

          // Increment page number only if data is retrieved
          if (data.data.length > 0) {
            setPageNumber((prevPage) => prevPage + 1);
          }
        } else {
          toast.error("No Certificates found!");
        }
      } else if (selectedManufacture && searchValue.length === 0) {
        const size = 100; // Fixed batch size
        const data = await getAllCertificates(
          pageNumber,
          size,
          selectedManufacture
        );

        if (data.status === 200 || data.status === 201) {
          setCertificates((prevCertificates) => [
            ...prevCertificates,
            ...data.data, // Append new batch
          ]);

          setPageCountValue(data.totalPages);
          setTotalResults(data.total);

          if (data.data.length === 0) {
            toast.info("No more certificates to load.");
          }

          // Increment page number only if data is retrieved
          if (data.data.length > 0) {
            setPageNumber((prevPage) => prevPage + 1);
          }
        } else {
          toast.error("No Certificates found!");
        }
      } else {
        const payload = {
          manufacturer: selectedManufacture,
          mpn: searchValue.length > 0 ? searchValue : null,
        };

        const data = await getCertificatesByManufactureCdoc(payload);
        if (data.status === 200 || data.status === 201) {
          setCertificates(data.data);
          setPageCountValue(data.data);
        } else {
          toast.error("No Certificates found!");
        }
      }
    } catch (error) {
      console.error(
        "Error during POST request:",
        error.response?.data || error.message
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const scrapeManufacturer = async () => {
    try {
      if (!selectedManufacture) {
        toast.error("Select Manufacturer");
        return;
      }
      setLoadingManufacture(true);

      const payload = {
        query: selectedManufacture,
        test_house: "UL",
        llm: "geminif",
        search_term: "company",
        max_pages: 200,
        max_rows: 200,
      };

      toast.success("Scraping Started!");
      const data = await getManufacturerByID(payload);
      if (data.status == 200 || data.status == 201) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(
        "Error during POST request:",
        error.response?.data || error.message
      );
      toast.success("Scraping in progress");
      throw error;
    }

    setLoadingManufacture(false);
  };

  useEffect(() => {
    let updatedData = certificates.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    console.log("updated Data ==>>>", updatedData);

    setShowingData(updatedData);
    console.log(updatedData, "showingData calling");
  }, [currentPage, manufacturers, certificates]);

  const renderRoHSTableInput = (
    value: string | undefined,
    type: string,
    id: number,
    index: number
  ) => {};

  const showPagesCount = (pageCount: number, currentPage: number) => {
    let array: number[] = Array.from(
      new Set([
        1,
        2,
        3,
        currentPage < 2 ? 1 : currentPage - 1,
        currentPage,
        currentPage >= pageCount ? pageCount : currentPage + 1,
        pageCount - 2,
        pageCount - 1,
        pageCount,
      ])
    )
      .sort((a, b) => a - b)
      .filter((el) => el > 0 && el <= pageCount);
    for (let i = 1; i < array.length; i++) {
      if (array[i] - 1 !== array[i - 1]) {
        array = [...array.slice(0, i), 0, ...array.slice(i)];
        i++;
      }
    }

    return array;
  };
  const SelectComponent = ({ value, onChange }: any) => {
    return (
      <td className="whitespace-nowrap align-top text-sm text-gray-500">
        {
          <select
            className="component-select rounded py-1.5 border border-gray-200 w-24 mr-1.5"
            // className={styles.componentSelect}
            onChange={onChange}
            value={value}
            name="componentStatus"
            id="component-status"
          >
            <option value={DOCUMENTCERTIFICATES.type}>TYPE</option>
            <option value={DOCUMENTCERTIFICATES.source}>SOURCE NAME</option>
            <option value={DOCUMENTCERTIFICATES.referenceNumber}>
              REFERENCE NUMBER
            </option>
            <option value={DOCUMENTCERTIFICATES.manufacturer}>
              MANUFACTURER
            </option>
          </select>
        }
      </td>
    );
  };
  const handleSelectChange = (e: any) => {
    console.log("======> ", e.target.value);
    setSearchDDValue(e.target.value);
  };
  const searchHandler = (e: any) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      console.log("-----====////////>", e.target.value);
      setCallbackState({});
    }
  };
  const submittSearch = () => {
    if (searchValue.length == 0) {
      // alert("Write something to search")
      toast.error("Write Something to Search");
      return;
    }
  };

  const handleAdd = () => {
    if (newmanufacturerName.trim() === "") {
      alert("Please enter a manufacturer name.");
      return;
    }
    console.log("Manufacturer added:", newmanufacturerName);
    addManufacture(newmanufacturerName);
    setIsAddManufacture(false);
    setNewManufacturerName(""); // Clear the input after adding
  };

  const handleCancel = () => {
    setIsAddManufacture(false);
    setNewManufacturerName(""); // Clear the input on cancel
  };
  return (
    <>
      <div className="flex">
        {/* {SelectComponent({ value: searchDD, onChange: handleSelectChange })} */}
        <Input
          style={{ width: "250px" }}
          value={searchValue}
          onChange={searchHandler}
          placeholder="Search component"
          inputValue={searchValue}
          handleClearInput={() => {
            setCallbackState({});
            setSearchValue("");
            // if (tempCertData.length > 0) {
            // }
          }}
        ></Input>
        <select
          id="manufacturer-select"
          className="component-select ml-4 rounded-md py-1.5 border shadow-sm border-gray-300 w-60 mr-1.5 text-sm text-gray-400"
          value={selectedManufacture ?? ""}
          onChange={handleManufacturerChange}
        >
          <option className="text-gray-300" value="" disabled>
            -- Select a Manufacturer --
          </option>
          <option className="text-gray-700" value="All">
            All
          </option>
          {manufacturers.map((manufacturer) => (
            <option
              className="text-gray-700"
              key={manufacturer.id}
              value={manufacturer.name}
            >
              {manufacturer.name}
            </option>
          ))}
        </select>
        <Button
          loading={loading}
          label="Get Certificates"
          className={"mr-2.5, ml-1.5"}
          onClick={() => {
            getCertificates();
          }}
        />
        <Button
          loading={loadingManufacture}
          label="Scrape"
          className={"mr-2.5, ml-1.5"}
          onClick={() => {
            scrapeManufacturer();
          }}
        />
        <Button
          label="Add Manufacturer +"
          className={"mr-2.5, ml-1.5"}
          onClick={() => setIsAddManufacture(true)}
        />
      </div>

      {
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeadings.map((heading) => (
                        <th
                          key={heading}
                          scope="col"
                          className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                          style={{ textAlign: "left" }}
                        >
                          {heading}
                        </th>
                      ))}
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>

                  {certificates.length != 0 ? (
                    <tbody className="">
                      {certificates
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((item: CertResult, index: number) => {
                          return (
                            <tr
                              className={
                                index % 2 === 0 ? undefined : "bg-gray-50"
                              }
                              key={index}
                            >
                              <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div style={{ maxWidth: 100 }}>
                                  {item.id.toString()}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 100 }}>
                                  {item.document_id ? item.document_id : ""}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 100 }}>
                                  <div style={{ maxWidth: 100 }}>
                                    {item.cert.ref_number
                                      ? item.cert.ref_number
                                      : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 100 }}>
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.cert.model ? item.cert.model : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 200 }}>
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.cert.rawVersion
                                      ? item.cert.rawVersion
                                      : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 200 }}>
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.name ? item.name : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 120 }}>
                                  <div style={{ maxWidth: 120 }}>
                                    {item.is_valid ? "TRUE" : "FALSE"}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 190 }}>
                                  <div
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.why_valid ? item.why_valid : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 150 }}>
                                  <div style={{ maxWidth: 150 }}>
                                    {item.cert.certifying_body
                                      ? item.cert.certifying_body
                                      : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 150 }}>
                                  <div style={{ maxWidth: 150 }}>
                                    <a
                                      href={
                                        item.url
                                          ? item.url
                                          : item.document_links &&
                                            item.document_links[0]?.link
                                          ? item.document_links[0].link
                                          : ""
                                      }
                                      target="_blank"
                                      className="text-primary_color hover:underline"
                                    >
                                      Document Link
                                    </a>
                                  </div>
                                </div>
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <div style={{ maxWidth: 150 }}>
                                  <div style={{ maxWidth: 150 }}>
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      className="text-primary_color hover:underline"
                                    >
                                      Document Link
                                    </a>
                                  </div>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <p className="relative py-3.5 text-center">
                      No Certificates Available!
                    </p>
                  )}
                </table>
                <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="flex-1 flex justify-between sm:hidden">
                    <button
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      onClick={() =>
                        setCurrentPage((currentPage) => currentPage - 1)
                      }
                    >
                      Previous
                    </button>
                    <button
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      onClick={() =>
                        setCurrentPage((currentPage) => currentPage + 1)
                      }
                    >
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing{" "}
                        <span className="font-medium">
                          {
                            certificates.length
                            //  materials?.length
                          }
                        </span>{" "}
                        of total{" "}
                        <span className="font-medium">
                          {
                            totalResults
                            //  materials?.length
                          }
                        </span>{" "}
                        results
                      </p>
                    </div>
                    <div>
                      <nav
                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                        aria-label="Pagination"
                      >
                        <button
                          onClick={() =>
                            setCurrentPage((currentPage) => currentPage - 1)
                          }
                          disabled={currentPage === 1}
                          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous</span>
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>

                        {/* {showPagesCount(pageCount, currentPage).map(
                          (elem, ind) => {
                            if (elem === 0) {
                              return (
                                <div
                                  key={elem}
                                  aria-current="page"
                                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                                >
                                  ...
                                </div>
                              );
                            }
                            return (
                              <button
                                key={elem}
                                onClick={() => setCurrentPage(elem)}
                                aria-current="page"
                                // className={
                                //   elem === currentPage
                                //     ? styles.activeBtn
                                //     : styles.defaultBtn
                                // }
                                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium${
                                  elem === currentPage
                                    ? " text-green"
                                    : "text-gray-700 "
                                }`}
                              >
                                {elem}1
                              </button>
                            );
                          }
                        )} */}
                        <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500">
                          {currentPage}
                        </div>
                        <button
                          disabled={currentPage === pageCountValue}
                          onClick={() => {
                            setCurrentPage((currentPage) => currentPage + 1);
                            getCertificates();
                          }}
                          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Next</span>
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                        {/* <div className="flex justify-center mt-3 mb-3">
                          <Button
                            label="LOAD MORE"
                            loading={loading}
                            onClick={() => getCertificates()}
                          />
                        </div> */}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              {/* <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination" 
              >
                <button
                  onClick={() =>
                    setCurrentPage((currentPage) => currentPage - 1)
                  }
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>

                {showPagesCount(pageCount, currentPage).map((elem, ind) => {
                  if (elem === 0) {
                    return (
                      <div
                        key={elem}
                        aria-current="page"
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                      >
                        ...
                      </div>
                    );
                  }
                  return (
                    <button
                      key={elem}
                      onClick={() => setCurrentPage(elem)}
                      aria-current="page"
                    >
                      {elem}
                    </button>
                  );
                })}

                <button
                  // disabled={pageCount === currentPage}
                  onClick={() =>
                    setCurrentPage((currentPage) => {
                      if (pageCount === currentPage) {
                        // getCertificates(currentPage + 3, 30);
                      }
                      console.log("page count   ", currentPage + 1);
                      return currentPage + 1;
                    })
                  }
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button> */}
              {/* </nav> */}
            </div>
          </div>
        </div>
      }
      {/* {selectedManufacture && (
        <CustomModal
          isShown={!!selectedManufacture}
          setModalShown={() => setSelectedManufacture(null)}
        >
          <ManufacturerCertificates manufacture={selectedManufacture} />
        </CustomModal>
      )} */}

      {isAddManufacture && (
        <CustomModal
          isShown={!!isAddManufacture}
          setModalShown={() => setIsAddManufacture(false)}
        >
          <div className="px-6 py-6 w-500">
            <h2
              className="text-1xl leading-10 font-medium text-primary-500"
              style={{ textAlign: "center" }}
            >
              Add Manufacturer
            </h2>
            <div className="mt-4">
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring-primary-200"
                placeholder="Enter name"
                value={newmanufacturerName}
                onChange={(e) => setNewManufacturerName(e.target.value)}
              />
            </div>
            <div className="flex justify-end mt-4 gap-4">
              <button
                className="px-4 w-full py-2 bg-primary_color text-white rounded-md hover:bg-primary-500"
                onClick={handleAdd}
              >
                Add
              </button>
              {/* <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                onClick={handleCancel}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default ManfacturerDetails;
